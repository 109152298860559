html{
  height: 100%;
}
body{
  height: inherit;
}
@font-face {
    font-family: hariboFont;
    src: url(./assets/fonts/VAGRoundedLTPro-Light.otf);
    font-weight: bold;
}
@font-face {
    font-family: hariboFontBold;
    src: url(./assets/fonts/VAGRoundedLTPro-Bold.otf);
    font-weight: bold;
}
#root{
  height: inherit;
  background-color: #F1B034;
  overflow: auto;
}
.App {

}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
input:-webkit-autofill {
  border-radius: inherit;
}
